<template>
  <div class="contract-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">一站式合同管理解决方案服务</div>
      <div class="pdc-cnt">
        通过仟金顶SaaS智能合同引擎，深耕泛建材领域，产业专一更贴近实际业务。<br/>从合同起草、流转审批、电子签约、通知发送、存证归档、出证以及履约过程中的变更、查询、统计，整个合同管理过程实现全程数字化。
      </div>
    </div>
    <div class="product-cnt">
      <div class="title">产品内容</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="item">
            <div class="sub-title">合同鉴权</div>
            <div>接入权威数据库，快速完成企业鉴权、自然人鉴权、签署意愿验证，实现精准核验</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="sub-title">合同管理</div>
            <div>日常签约存档调用，自定义合同模板、电子签章管理</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="sub-title">存证取证</div>
            <div>在线存证信息调取，包含签署人各项基本信息、鉴权信息及合同签署信息，支持出具多种形式的证据报告</div>
          </div>
        </swiper-slide>
        <div class="contract-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="advantage">
      <div class="pdc-title">签约高效便捷</div>
      <div class="adv-list-item">
        <div class="title">完美适配多终端</div>
        <div>PC、移动一体化，随时随地高效签约</div>
      </div>
      <div class="adv-list-item">
        <div class="title">标准合同模板</div>
        <div>支持多种格式文件或直接使用自定义模板发起签署</div>
      </div>
      <div class="adv-list-item">
        <div class="title">缩短签约周期</div>
        <div>简化用户的操作流程，降低签署周期，提高成单率</div>
      </div>
    </div>
    <div class="application">
      <div class="pdc-title">无需繁杂技术开发</div>
      <div class="app-list">
        <div class="app-item">
          <div class="title">支持已有系统对接</div>
          <div>支持对接企业既有的业务系统，不改已有的业务流程</div>
        </div>
        <div class="app-item">
          <div class="title">低成本API标准接口</div>
          <div>提供标准的API接口，灵活简单易用，集成成本低周期短</div>
        </div>
      </div>
    </div>
    <div class="league">
      <div class="pdc-title">有效法律效力</div>
      <div class="league-list">
        <div class="item">
          <div>
            <div class="title">真实身份</div>
            <div>通过实名认证的技术手段，实现电子签名人身份及该身份操作</div>
          </div>
        </div>
        <div class="item">
          <div>
            <div class="title">真实意愿</div>
            <div>意愿认证是通过短信验证码、刷脸认证等实现电子签名人进行电子签名时的真实意愿表达</div>
          </div>
        </div>
        <div class="item">
          <div>
            <div class="title">原文未改</div>
            <div>通过签名验证、数字证书等手段签署后对数据电文内容和形式的任何改动能够被发现</div>
          </div>
        </div>
        <div class="item">
          <div>
            <div class="title">签名未改</div>
            <div>经过哈希算法，可信时间戳等技术手段实现签署后对电子签名的任何改动能够被发现和形式的任何改动能够被发现</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">安全合同存储</div>
      <div class="pdc-list-item">
        <div class="title">DJCP等保三级</div>
        <div>公安部信息系统安全等级保护三级</div>
        <div>非银行金融机构能够获得的最高级别</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">分段式加密存储</div>
        <div>分段式加密存储即将随机密钥分为多段</div>
        <div>每段信息使以数字信封的形式加密后保存</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">网络安全</div>
        <div>DDOS、CC攻击防护 SSL/TLS全程加密</div>
        <div>WAF防火墙安全防护安全组、边界安全防护</div>
      </div>
    </div>
    <div class="process-info">
      <div class="pdc-title">业务流程</div>
      <swiper ref="mySwiper" :options="swiperOptions2">
        <swiper-slide>
          <img class="process-img left" src="../../../assets/images/product/contract-1.png">
        </swiper-slide>
        <swiper-slide>
          <img class="process-img right" src="../../../assets/images/product/contract-2.png">
        </swiper-slide>
        <div class="process-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.contract-pagination',
        },
        loopedSlides: 3,
        slidesPerView: 1.24,
        spaceBetween: 20,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        loop: true
      },
      swiperOptions2: {
        scrollbar: {
          el: '.process-scrollbar',
        },
        freeMode : true,
      }
    
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.contract-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .product-cnt{
    background: url('../../../assets/images/product/contract-lignt-bg.png');
    background-size: 100% auto;
    height: 440px;
    .title{
      text-align: center;
      font-size: 36px;
      padding-top: 46px;
      padding-bottom: 50px;
    }
    .item{
      background-color: #fff;
      height: 228px;
      padding: 36px;
      font-size: 24px;
      line-height: 32px;
      .sub-title{
        font-size: 28px;
        position: relative;
        padding-left: 12px;
        margin-bottom: 24px;
        &::before{
          content: '';
          width: 4px;
          height: 20px;
          background-color: #CEB287;
          position: absolute;
          top: 6px;
          left: 0;
        }
      }
    }
    .contract-pagination{
      text-align: center;
      .swiper-pagination-bullet{
        width: 12px;
        height: 6px;
        background-color: #fff;
        opacity: 1;
        border-radius: 0;
      }
      .swiper-pagination-bullet-active{
        background-color: #3D538C;
        width: 24px;
      }
    }
  }
  .application{
    height: 550px;
    background: url('../../../assets/images/product/contract-bg.png') no-repeat;
    background-size: 100% auto;
    .app-list{
      flex-direction: column;
      .app-item{
        text-align: left;
        padding: 15px 43px 45px;
        width: auto;
        height: 152px;
        color: #595961;
        margin-bottom: 20px;
        .title{
          font-size: 28px;
          position: relative;
          margin-bottom: 10px;
          &::after{
            content: '';
            background-color: #CEB287;
            width: 4px;
            height: 20px;
            position: absolute;
            top: 10px;
            left: -14px;
          }
        }
        &>div{
          padding: 0;
        }
      }
    }
  }
  .league{
    .league-list{
      padding: 0 32px 50px;
      display: flex;
      flex-wrap: wrap;
      .item{
        padding: 6px;
        width: 343px;
        >div{
          background-color: #F5F5F7;
          height: 300px;
          line-height: 32px;
          padding: 36px;
          .title{
            font-size: 28px;
            padding: 8px 0 24px 20px;
            position: relative;
            &::before{
              content: '';
              width: 4px;
              height: 20px;
              background-color: #CEB287;
              position: absolute;
              top: 14px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .pdc-info{
    background: #F5F5F7;
    .pdc-list-item{
      border: 1PX solid #E0E0E0;
      border-bottom: none;
      margin: 0 38px;
    }
  }
  .process-info{
    .process-scrollbar{
      margin-top: 60px;
    }
  }
}
</style>