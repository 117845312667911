<template>
  <div class="risk-engine-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">风险引擎</div>
      <div class="pdc-cnt">
        风险引擎基于网络公开数据，对企业进行多维度、多场景的商业、风险、发展潜能等画像构建，并对相关互联网舆情内容实时监测与智能深度分析，帮助企业快速了解其自身、合作伙伴、竞争对手的基本商业运营情况与相关潜在风险和内部关联关系，最终为商业决策提供依据。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">仟金眼</div>
        <div>企业商业信息报告查询</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">舆情雷达</div>
        <div>企业舆情风险智能监控平台，助力企业规避合作风</div>
        <div>险、提升合作企业风险管控力度</div>
      </div>
    </div>
    <div class="application">
      <div class="pdc-title">应用场景</div>
      <div class="app-list">
        <div class="app-item">
          <img src="../../../assets/images/product/risk.png" class='topic'>
          <div class="title">提前透查合作企业资质</div>
          <div>品牌厂家在开拓新下游企业时，需提前透查资质，为合作提供决策依据</div>
        </div>
        <div class="app-item">
          <img src="../../../assets/images/product/riskno.png" class='topic'>
          <div class="title">贸易风险实时预警</div>
          <div>需要实时掌握合作中项目/企业的一手风险信息，提前预警贸易风险</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="title">海量数据采集与分析</div>
        <div>采集全网数千个公开网站、百万自媒体数据源，保障数据量级与更新及时性， 通过智能情感分析技术，基于自然语言处理技术对内容实体进行情感倾向预测，对主观内容进行量化处理，帮助用户快速了解社会对企业评价</div>
      </div>
      <div class="adv-list-item">
        <div class="title">多维评级模型</div>
        <div>智能五维评级模型，依赖仟金顶多年行业与企业评级策略而搭建的智能评级模型，通过对复杂关系网络分析与海量关系节点运算，找出企业间内在潜在关系</div>
      </div>
      <div class="adv-list-item">
        <div class="title">实时更新</div>
        <div>7x24h,不间断数据更新避免因数据不及时遗漏风险预警</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, swiper, swiperSlide, SignButton
  },
  data() {
    return {
      swiperOptions: {
        direction : 'horizontal',
        pagination: {
          el: '.process-pagination',
        },
        freeMode : true,
      }
    
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.risk-engine-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
}
</style>