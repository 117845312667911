<template>
  <div class="sales-tools-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">销售工具</div>
      <div class="pdc-cnt">
        销售工具为企业提供便捷的线上获客工具，企业可自定义配置营销活动图片和文案，支持多渠道的客户触达，意向客户池统一管理，打破销售壁垒，提高获客效率。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">营销管理</div>
        <div>支持营销活动图片、文案自定义编辑，灵活配置</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">意向客户收集</div>
        <div>在线表单收集意向客户信息，后台留存，跟进触达</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">一键分享</div>
        <div>在线生成活动信息，一键转发多个渠道，轻松触达客户</div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">自定义营销活动，灵活配置</div>
          <div>支持上传营销图片，自定义营销活动内容</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/sale-adv-1.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">打破销售壁垒，多渠道获客</div>
          <div>在线生成活动信息，一键转发多个渠道，</div>
          <div>轻松触达客户</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/sale-adv-2.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">线索集中管理</div>
          <div>在线表单收集线索，意向客户统一管理，</div>
          <div>一键导入CRM</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/sale-adv-3.png">
        </div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.sales-tools-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .advantage{
    .adv-list-item{
      display: flex;
      justify-content: space-between;
      padding: 18px 29px 20px;
      .title{
        margin: 20px 0 20px;
        &::before{
          width: 68px;
          height: 4px;
          background-color: #EAEBEE;
          top: 46px;
          left: 0px;
        }
      }
      .right{
        height: 145px;
        img{
          width: 145px;
        }
      }
    }
  }
}
</style>