<template>
  <div class="da-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">客户评估</div>
      <div class="pdc-cnt">
        全网采集并解析建筑工程商、项目数据，综合评估建筑工程商、单个项目及关联公司实力，并应用仟金顶独有项目评估机制，为企业方提供项目的合作价值和合作风险评估服务。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">建筑工程商综合实力看板</div>
        <div>多维交叉分析，评估TOP100建筑工程商市场竞争力</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">建筑工程商评估</div>
        <div>四维综合分析，评估建筑工程商合作履约能力</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">关联公司评估</div>
        <div>关联关系可视化，全链路防控风险</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">项目评估</div>
        <div>独有专家评估模型，系统评估项目合作风险</div>
      </div>
    </div>
    <div class="application">
      <div class="pdc-title">应用场景</div>
      <div class="app-list">
        <div class="app-item">
          <img src="../../../assets/images/product/project.png" class='topic'>
          <div class="title">合作中需实时掌握风险信息</div>
          <div>企业需实时掌握合作中建筑工程商的一手风险信息，提前预警合作风险</div>
        </div>
        <div class="app-item">
          <img src="../../../assets/images/product/projectno.png" class='topic'>
          <div class="title">缺少风险评估工具</div>
          <div>企业承接建筑工程商项目时，缺少系统和客观的风险评估工具，依赖人工判断</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="title">海量数据</div>
        <div>1、全国400个城市拿地数据</div>
        <div>2、全国120个重点城市项目数据</div>
        <div>3、TOP100建筑工程商研报数据</div>
        <div>4、全网建筑工程商舆情数据</div>
        <div>5、全网建筑工程商征信数据</div>
      </div>
      <div class="adv-list-item">
        <div class="title">前沿技术</div>
        <div>1、NLP自然语言处理</div>
        <div>2、数据挖掘技术</div>
        <div>3、AI机器学习</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.da-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
}
</style>