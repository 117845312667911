<template>
  <div class="home-product">
    <SignButton></SignButton>
  </div>
</template>

<script>
import SignButton from '@/components/signButton'
export default {
  components: {
    SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.home-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
}
</style>