<template>
  <div class="sale-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">赊销系统</div>
      <div class="pdc-cnt">
        全链路的赊销流程管理，赋能品牌厂家开展自主赊销的精细化管理，包括授信、用信、控信、还款管理，来为品牌厂家降低赊销风险，同时满足业务增长和下游企业拓展需求，推动业务的健康发展。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">授信管理</div>
        <div>授信规则灵活配置，多维度预防赊销风险</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">用信管理</div>
        <div>资金路由及用信规则配置，实现用信精细化管理</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">控信管理</div>
        <div>逾期风险监测预警，坏账催收及诉讼管理</div>
      </div>
    </div>
    <div class="process-info">
      <div class="pdc-title">流程介绍</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="process-img left" src="../../../assets/images/product/process-sale-1.png">
        </swiper-slide>
        <swiper-slide>
          <img class="process-img right" src="../../../assets/images/product/process-sale-2.png">
        </swiper-slide>
        <div class="process-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="application">
      <div class="pdc-title">应用场景</div>
      <div class="app-list">
        <div class="app-item">
          <img src="../../../assets/images/product/sale.png" class='topic'>
          <div class="title">有完善的经销体系和赊销政策</div>
          <div>企业有相对完善的经销商体系，并提供自有赊销政策或自有资金及第三方银行等渠道共同提供赊销资金</div>
        </div>
        <div class="app-item">
          <img src="../../../assets/images/product/saleno.png" class='topic'>
          <div class="title">无线上赊销管理系统</div>
          <div>企业有业务增长和下游企业拓展需求，虽然有内部业务管理系统，但是缺少线上赊销管理体系</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">服务优势</div>
      <div class="adv-list-item">
        <div class="title">提高管理效率</div>
        <div>赊销流程全链路线上化管理，帮助品牌厂家提升管理</div>
        <div>效率</div>
      </div>
      <div class="adv-list-item">
        <div class="title">合理分配额度</div>
        <div>额度管理规则及用信管理线上化、系统化，帮助核心</div>
        <div>企业合理分配额度，有助于赊销政策更好落地，推动</div>
        <div>业务健康发展</div>
      </div>
      <div class="adv-list-item">
        <div class="title">降低赊销风险</div>
        <div>还款管理线上化、系统化，帮助品牌厂家及时了解待</div>
        <div>还及还款情况，降低品牌厂家赊销风险</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, swiper, swiperSlide, SignButton
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.process-scrollbar',
        },
        freeMode : true,
      }
    
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.sale-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
}
</style>