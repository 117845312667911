<template>
  <div class="ld-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">经销商分层</div>
      <div class="pdc-cnt">
        基于目标经销商基本信息、实控人信息、企业经营数据等信息，建立基于经销商经营的风险评估模型，输出经销商信用分层，完善经销商评估体系，合理分配资源，锁定优质经销商，同时管理过程实现全程数字化。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">标准模型</div>
        <div>经销商诚信等级分层</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">联合建模</div>
        <div>结合企业关键因子</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">数据标签</div>
        <div>数据脱敏后标准API接口输出</div>
      </div>
    </div>
    <div class="application">
      <div class="pdc-title">应用场景</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="item">
            <img src="../../../assets/images/product/ld-adv-1.png">
            <div class="sub-title">日常经销商管理</div>
            <div>评优、汰换，有据可依</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img src="../../../assets/images/product/ld-adv-2.png">
            <div class="sub-title">赊销政策分配</div>
            <div>识别客户风险，精细化分配赊销政策</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img src="../../../assets/images/product/ld-adv-3.png">
            <div class="sub-title">推送第三方合作</div>
            <div>提前筛查、排黑，避免无效的资料提审</div>
          </div>
        </swiper-slide>
        <div class="contract-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="title">垂直领域独有数据沉淀</div>
        <div>实现从数据沉淀到数据贯通，以数据反哺企业，系统</div>
        <div>化、标准化分配赊销政策，降低赊销风险</div>
      </div>
      <div class="adv-list-item">
        <div class="title">精准高效分层</div>
        <div>以经营、工商、涉诉、征信等刻画用户画像，增加行</div>
        <div>为，时序等数据，实现精准高效的经销商分层管理</div>
      </div>
      <div class="adv-list-item">
        <div class="title">联合建模</div>
        <div>结合企业自身评估维度要素，联合建模，满足业务发</div>
        <div>展需求</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.contract-scrollbar',
        },
        loopedSlides: 3,
        slidesPerView: 2.3,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        freeMode: true,
      },
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.ld-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .application{
    .title{
      text-align: center;
      font-size: 36px;
      padding-top: 46px;
      padding-bottom: 50px;
    }
    .item{
      background-color: #fff;
      height: 395px;
      padding: 40px 20px;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #A9AFB8;
      .sub-title{
        font-size: 28px;
        margin-bottom: 20px;
        margin-top: 30px;
        color: #252631;
      }
      img{
        width: 150px;
        display: block;
        margin: 0 auto;
      }
    }
    .contract-scrollbar{
      height: 8px;
      width: 100px;
      margin: 40px auto 10px;
      background-color: #EAEBEE;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>