<template>
  <div class="data-cockpit-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">数据驾驶舱</div>
      <div class="pdc-cnt">
        数据驾驶舱为企业提供可视化业务、风险数据系统，通过对业务、风险等数据详尽的指标分析，为企业经营管理决策提供快速、直观、纵览全局的数据支持。
      </div>
    </div>
    <div class="application">
      <div class="pdc-title">服务模块</div>
      <div class="app-list">
        <div class="app-item">
          <div class="title">业务看板</div>
          <div>全盘销售业务分析，为日常业务管理、优化市场销售策略提供决策参考</div>
        </div>
        <div class="app-item">
          <div class="title">风险看板</div>
          <div>智能风险数据看板，合作企业舆情、涉诉数据全盘分析，为业务决策保驾护航</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">可视化业务看板</div>
      <div class="adv-list-item">
        <div class="title">合作企业数据动态展现</div>
        <div>分企业、分时段、分区域展示业务合作数据，业务情况一目了然</div>
      </div>
      <div class="adv-list-item">
        <div class="title">多维交叉分析业务数据</div>
        <div>年/季/月数据交叉分析，经营状况清晰明了</div>
      </div>
      <div class="adv-list-item">
        <div class="title">经营预警动态提醒</div>
        <div>经营健康状况每日监测，动态提醒，开启智慧经营</div>
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">风险数据深度剖析</div>
      <div class="pdc-list-item">
        <div class="title">风险数据全面洞察</div>
        <div>智能风险数据看板，合作企业舆情、涉诉数据尽在掌握</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">多维数据分析助力业务决策</div>
        <div>包含合作企业的司法诉讼、失信限高、行政处罚、动产抵押等多维数据的交叉分析，为业务决策保驾护航</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.data-cockpit-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .application{
    background: url('../../../assets/images/product/dataCockpit-bg.png');
    background-size: 100% auto;
    height: 500px;
    .app-list{
      .app-item{
        text-align: left;
        padding: 36px 46px 0;
        width: 325px;
        height: 290px;
        color: #595961;
        .title{
          font-size: 28px;
          position: relative;
          margin-bottom: 28px;
          &::after{
            content: '';
            background-color: #CEB287;
            width: 30px;
            height: 4px;
            position: absolute;
            top: 40px;
            left: 0;
          }
        }
        &>div{
          padding: 0;
        }
      }
    }
  }
}
</style>