<template>
  <div class="bidding-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">招投标管理</div>
      <div class="pdc-cnt">
        招投标管理通过实时采集招投标信息，应用精准的自然语言处理算法，实现准确定制化商情的实时呈现、跟踪、推送，使企业能够及时发现商机、全量获知商机、实时跟踪项目进展、深度了解竞争企业信息，从而提高业务量。
      </div>
    </div>
    <div class="pdc-content">
      <div class="pdc-title">产品内容</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/bidding-1.png">
            <div class="info">
              <div class="title">招投标查询</div>
              <div>涵盖全国多行业权威机构(政府、招标机构、企业)的各类招投标信息，覆盖招标预告、招标公告、变更公告、招标结果，为企业提供全面商机资讯</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/bidding-2.png">
            <div class="info">
              <div class="title">收藏订阅</div>
              <div>支持收藏和根据偏好订阅招投标信息，寻觅商机不迷路</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/bidding-3.png">
            <div class="info">
              <div class="title">实时推送</div>
              <div>订阅内容精准锁定，最新动态实时跟踪推送随时随地把握商机</div>
            </div>
          </div>
        </swiper-slide>
        <div class="bidding-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品优势</div>
      <div class="pdc-list-item">
        <div class="title">多行业、全国海量数据</div>
        <div>招投标信息涵盖全国多行业权威机构（政府、招标机构、企业）的各类招投标信息，覆盖招标预告、招标公告、变更公告、招标结果，为用户提供全面商机资讯</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">全时段商机实时更新</div>
        <div>7*24小时实时采集招投标信息，智能分析归类，实时监测商机</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">自定义关键词订阅招投标信息</div>
        <div>支持根据偏好设置关键词、目标企业、区域等订阅条件，精准锁定有效招投标信息</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">实时推送把握商机</div>
        <div>订阅内容更新实时推送，随时、随地接收提醒，商机尽在掌握</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.bidding-scrollbar',
        },
        slidesPerView: 1.24,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        freeMode : true,
      },
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.bidding-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .pdc-cnt{
    padding-bottom: 42px;
  }
  .pdc-content{
    .item{
      box-shadow: -5px 5px 25px 0px #E7E7E7;
      width: 590px;
      font-size: 24px;
      .title-pic{
        display: block;
        width: 590px;
      }
      .info{
        line-height: 34px;
        padding: 30px 24px 0;
        height: 230px;
        color: #595961;
        .title{
          font-size: 28px;
          line-height: 42px;
          color: #252631;
          font-weight: bold;
          margin-bottom: 6px;
        }
      }
    }
    .bidding-scrollbar{
      height: 8px;
      width: 140px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 70px;
      background-color: #EAEBEE;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>