<template>
  <div class="eye-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">仟金眼</div>
      <div class="pdc-cnt">
        基于工商数据和公开信息，为查询者提供企业多维度画像、商业报告等服务，深度剖析企业基本资质、实力情况，帮助查询者锁定优质合作。
      </div>
    </div>
    <div class="pdc-content">
      <div class="pdc-title">产品内容</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/eye-1.png">
            <div class="info">
              <div class="title">企业画像</div>
              <div>仟金顶独创五维评价模型，快速了解企业发展潜力与综合实力</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/eye-2.png">
            <div class="info">
              <div class="title">关联图谱</div>
              <div>1. 支持查询两家企业通过法人、任职、等6个维度找出两家企业之间内部潜在关联关系，一键分析潜在风险</div>
              <div>2. 支持查询某企业与之关联的全部企业与个人的关联关系</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/eye-3.png">
            <div class="info">
              <div class="title">股权图谱</div>
              <div>支持查询目标企业的工商股东，且可视化的呈现公司多层次股权结构，帮助客户快速高效地理清企业投资股权关系</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/product/eye-4.png">
            <div class="info">
              <div class="title">风险扫描</div>
              <div>通过“自身风险”“关联风险”“预警提醒”了解被查询企业的风险与关联风险</div>
            </div>
          </div>
        </swiper-slide>
        <div class="eye-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品优势</div>
      <div class="pdc-list-item">
        <div class="title">仟金顶独创五维评价模型</div>
        <div>仟金顶独创五维评价模型，从企业综合实力、司法风险、经营状况、履约能力、外部关联等维度综合评估企业潜力和实力</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">多维度交叉查询风险</div>
        <div>多维度交叉查询风险、不仅仅是单一企业风险信息订阅而是基于企业之间的复杂关联关系而提供的风险预警信息</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">舆情动态实时更新预警</div>
        <div>全网抓取目标企业的相关舆情信息，并进行情感语义分析，智能分类</div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">关联关系、企业股权层层穿透</div>
      <div class="adv-list-item">
        <div class="title">关联关系可视化</div>
        <div>通过强大的复杂关系网络计算，通过数据可视化手段直观展示企业之间潜在关系</div>
      </div>
      <div class="adv-list-item">
        <div class="title">股权层层穿透，企业情况更透明</div>
        <div>一图看清企业对外投资、股东股权情况，找寻企业背后的受益人和控制人</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.eye-scrollbar',
        },
        slidesPerView: 1.24,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        freeMode : true,
      },
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.eye-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .pdc-cnt{
    padding-bottom: 42px;
  }
  .pdc-content{
    .item{
      box-shadow: -5px 5px 25px 0px #E7E7E7;
      width: 590px;
      font-size: 23px;
      .title-pic{
        display: block;
        width: 590px;
      }
      .info{
        font-size: 22px;
        line-height: 34px;
        padding: 30px 24px 0;
        height: 230px;
        color: #595961;
        .title{
          font-size: 28px;
          line-height: 42px;
          color: #252631;
          font-weight: bold;
          margin-bottom: 6px;
        }
      }
    }
    .eye-scrollbar{
      height: 8px;
      width: 140px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 86px;
      background-color: #EAEBEE;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>