<template>
  <div class="erp-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">ERP</div>
      <div class="pdc-cnt">
        仟金顶ERP系统为小微企业提供供应商管理、产品中心和订单管理等一体化全面解决方案，助力小微企业实现业务数字化转型，帮助企业在大数据时代平稳、健康的发展，有效提升运营效率。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">订单管理</div>
        <div>订单信息全链路存储管理，业务状况更清晰</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">供应商管理</div>
        <div>在线管理供货方信息，合作信息、历史数据一目了然</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">库存管理</div>
        <div>管理多渠道产品库存，根据库存调整销售、采购计划</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">产品中心</div>
        <div>商品集中管理，进销动态一目了然，并支持一键分享</div>
        <div>商品，快速获取精准用户</div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">合作历史便捷查看</div>
          <div>合作年限、采购金额等数据一目了然，快速</div>
          <div>了解合作历史</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/erp-adv-1.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">在线管理供应商</div>
          <div>支持新建供应商，在线编辑供应商档案，实</div>
          <div>现供应商从分散到集中管控，规范引入，预</div>
          <div>防风险</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/erp-adv-2.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">轻松管理商品</div>
          <div>对列表所有商品支持编辑、分组、删除以及</div>
          <div>上下架，同时支持以表格形式批量导入商</div>
          <div>品，可快速提升企业工作效率</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/erp-adv-3.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">商品库存智能管控</div>
          <div>自主设置库存预警线，触发提醒</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/erp-adv-4.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">多端协同更便捷</div>
          <div>支持PC端和APP端协同管理，提升企业运</div>
          <div>营效率</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/erp-adv-5.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">业务在线化</div>
          <div>供应商、产品及订单在线集中规范管理，实</div>
          <div>现业务全链路数字化，比传统线下记录更高</div>
          <div>效</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/erp-adv-6.png">
        </div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.erp-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .advantage{
    .adv-list-item{
      display: flex;
      justify-content: space-between;
      padding: 28px 29px 20px;
      .title{
        margin: 8px 0 20px;
        &::before{
          width: 68px;
          height: 4px;
          background-color: #EAEBEE;
          top: 46px;
          left: 0px;
        }
      }
      .right{
        align-self: center;
        height: 134px;
        img{
          width: 134px;
        }
      }
    }
  }
}
</style>