<template>
  <div class="pad-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">项目评估</div>
      <div class="pdc-cnt">
        项目评估服务解决了企业在承接建筑工程商项目时缺少风险评估工具、依赖人工判断的问题，帮助企业全方位、多维度了解和掌握建筑工程商及项目的一手风险信息，为企业承接项目提供风险决策依据。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">基本项目评估</div>
        <div>无需上传合同，一键查询项目，秒级更新，项目相关信息提前知晓</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">人工项目评估</div>
        <div>专家人工评估，综合多维度全方位的项目信息，评估更专业，有助于合作中及时把控项目合作风险</div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">海量项目数据，实时更新</div>
          <div>仟金顶独有项目库，并通过公开网站采集各</div>
          <div>类项目信息，海量项目实时更新</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/pad-adv-1.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">无需上传合同，操作简便</div>
          <div>项目评估无需上传项目合同， 即可提前知</div>
          <div>晓项目、建筑工程商等基本信息，为合作保</div>
          <div>驾护航</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/pad-adv-2.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">多维度全方位评估项目风险</div>
          <div>综合建筑工程商、项目公司的基本信息、财</div>
          <div>务、舆情等多维度的数据，系统化评估合作</div>
          <div>项目的履约能力</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/pad-adv-3.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">专家评估模型更专业</div>
          <div>基于仟金顶泛建材行业深耕经验及自有项目</div>
          <div>数据库，应用仟金顶独有专家评估模型，帮</div>
          <div>助企业及时把控项目合作风险</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/pad-adv-4.png">
        </div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.pad-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .advantage{
    .adv-list-item{
      display: flex;
      justify-content: space-between;
      padding: 28px 29px 20px;
      .title{
        margin: 8px 0 20px;
        &::before{
          width: 68px;
          height: 4px;
          background-color: #EAEBEE;
          top: 46px;
          left: 0px;
        }
      }
      .right{
        align-self: center;
        height: 134px;
        img{
          width: 134px;
        }
      }
    }
  }
}
</style>