<template>
  <div class="pm-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">项目管理</div>
      <div class="pdc-cnt">
        以项目为中心，涵盖项目全生命周期的精细化管理，提升企业对工程项目的经营管理能力，同时实现在工程业务运作层次上的规范管理和高效运作。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">售前管理</div>
        <div>内控成本和报价，实现动态成本管控和精准报价</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">签约发货管理</div>
        <div>从合同签订、物料管理到发货签约的全链路在线管</div>
        <div>理，提升项目的整体效率</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">项目实施中心</div>
        <div>施工、安装、交付的全流程闭环管理，确保项目顺利</div>
        <div>开展与验收交付</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">项目资金管理</div>
        <div>帮助企业建立资金科目、计划及收支管理档案，理清</div>
        <div>应收应付账目，降低资金风险</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">进度管理</div>
        <div>实现项目关键节点管控，预设提醒节点，实现标准推</div>
        <div>送与进度监督</div>
      </div>
    </div>
    <div class="application">
      <div class="pdc-title">应用场景</div>
      <div class="app-list">
        <div class="app-item">
          <img src="../../../assets/images/product/project.png" class='topic'>
          <div class="title">项目分散,管理混乱</div>
          <div>项目数量多，缺乏统筹管</div>
          <div>理工具，无法掌握</div>
          <div>全盘项目进度</div>
        </div>
        <div class="app-item">
          <img src="../../../assets/images/product/projectno.png" class='topic'>
          <div class="title">项目过程管控缺失,运营效率低</div>
          <div>项目关键节点缺乏精细化管控，项目进度导致项目内外部协同效率低</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="title">项目全链路监督管理</div>
        <div>涵盖项目成本、报价、合同、发货、施工、验收、资金</div>
        <div>等全生命周期的精细化管理，使项目进程清晰透明，提</div>
        <div>升企业业务运转效率</div>
      </div>
      <div class="adv-list-item">
        <div class="title">关键节点动态提醒</div>
        <div>支持项目关键节点的预设提醒，确保项目顺利开展，提</div>
        <div>高企业对工程项目的经营管理能力</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.pm-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
}
</style>