<template>
  <div class="crm-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">CRM</div>
      <div class="pdc-cnt">
        仟金顶CRM为小微企业提供轻量化的在线客户关系管理工具，帮助企业沉淀客户数据资产；高效利用线索，轻松做好客户开发和过程管控，提升业务转化。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">客户管理</div>
        <div>客户信息全方位线上统一管理，高效沉淀客户数据资产</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">进度管理</div>
        <div>在线维护客户跟踪记录，销售进度实时把控</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">快捷拨号</div>
        <div>一键拨号，即刻触达意向客户</div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">精细化客户跟进管理</div>
          <div>客户跟进记录在线维护，销售进度实时掌握</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/crm-adv-1.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">客户成熟度深度洞察</div>
          <div>客户标签精细分类，深度洞察客户成交意愿</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/crm-adv-2.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">客户信息全面记录</div>
          <div>客户工商信息自动补全，工商数据完整呈现，帮助企业沉淀客户数据资产</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/crm-adv-3.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">生命周期全程管理</div>
          <div>客户从线索至成交的完整记录，精细管理不断提升客户价值</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/crm-adv-4.png">
        </div>
      </div>
      <div class="adv-list-item">
        <div class="left">
          <div class="title">一键拨号快速外呼</div>
          <div>系统一键快速拨号，无需手动输入，节省操作时间，提升外呼作业效率</div>
        </div>
        <div class="right">
          <img src="../../../assets/images/product/crm-adv-5.png">
        </div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
export default {
  components: {
    TopBanner, SignButton
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.crm-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .advantage{
    .adv-list-item{
      display: flex;
      justify-content: space-between;
      padding: 28px 17px 28px 28px;
      .title{
        margin: 12px 0 20px;
        &::before{
          width: 68px;
          height: 4px;
          background-color: #EAEBEE;
          top: 46px;
          left: 0px;
        }
      }
      .right{
        height: 134px;
        img{
          width: 134px;
        }
      }
    }
  }
}
</style>