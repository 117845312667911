<template>
  <div class="open-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">开放平台</div>
      <div class="pdc-cnt">
        企业可通过标准开发接口，将内部业务系统与仟金顶业务系统无缝对接，取代人工对接各类业务资料和信息，实现用户行为、风险数据实时互通，提高业务开展效率。
      </div>
    </div>
    <div class="process-info">
      <div class="pdc-title">产品交互</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="process-img left" src="../../../assets/images/product/process-open-1.png">
        </swiper-slide>
        <swiper-slide>
          <img class="process-img right" src="../../../assets/images/product/process-open-2.png">
        </swiper-slide>
        <div class="process-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="title">标准开发接口</div>
        <div>提供标准开发接口，开发成本低，省时省力</div>
      </div>
      <div class="adv-list-item">
        <div class="title">支持已有系统对接</div>
        <div>支持对接企业现有业务系统，不改变现有业务流程</div>
      </div>
      <div class="adv-list-item">
        <div class="title">可靠的技术底层支撑</div>
        <div>区块链技术支撑，协调企业内外部资源，构建真实可</div>
        <div>靠的商业生态</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.process-scrollbar',
        },
        freeMode : true,
      }
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.open-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .process-info{
    background-color: #F5F5F7;
    .process-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 26px;
      background-color: #FFF;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>