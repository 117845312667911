<template>
  <div class="resources-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">资源赋能</div>
      <div class="pdc-cnt">
        仟金顶深耕于泛建材行业，依托各垂直领域行业特性，并通过真实贸易的数据沉淀带来的信用价值，整合内外部供应链资源，为企业提供基于供应链全链路的资金、物流、信息技术等全闭环服务，助力企业业务健康发展，提升企业业务运转效率，快速抢占市场。
      </div>
    </div>
    <div class="process-info">
      <div class="pdc-title">资源赋能</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="process-img left" src="../../../assets/images/product/process-resources-1.png">
        </swiper-slide>
        <swiper-slide>
          <img class="process-img right" src="../../../assets/images/product/process-resources-2.png">
        </swiper-slide>
        <div class="process-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="advantage">
      <div class="pdc-title">产品优势</div>
      <div class="adv-list-item">
        <div class="title">提高企业业务周转效率</div>
        <div>通过仟金顶的资源赋能，企业在承接业务时可以缓解</div>
        <div>采购现款现货导致的资金流缺口，极大的提高了企业</div>
        <div>业务周转效率</div>
      </div>
      <div class="adv-list-item">
        <div class="title">提升品牌市场份额</div>
        <div>通过快速的业务周转，企业销售与采购量获得显著提</div>
        <div>高，有助于抢占竞品市场份额，并提高自身品牌的市</div>
        <div>场占有率</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.process-scrollbar',
        },
        freeMode : true,
      }
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.resources-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .process-info{
    background-color: #F5F5F7;
    .process-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 26px;
      background-color: #FFF;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>