<template>
  <div class="supply-chain-product">
    <TopBanner/>
    <div class="total-info">
      <div class="pdc-title">供应链协同</div>
      <div class="pdc-cnt">
        供应链协同将品牌厂家与下游紧密衔接在一起，通过线上贸易协同，将商流、物流、信息流、资金流形成一体化运作；通过开放的生态融合服务，为企业提供智能化的全链路追踪、管理和协同，从而使品牌厂家业务在线管理，通过系统承载实现降本增效。
      </div>
    </div>
    <div class="pdc-info">
      <div class="pdc-title">产品内容</div>
      <div class="pdc-list-item">
        <div class="title">产品中心</div>
        <div>SKU级产品管理功能，支撑在线产品导购和营销推广</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">交易中心</div>
        <div>建立线上贸易通路，实时跟踪订单流转及状态变更</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">物流中心</div>
        <div>电子签收线上存证，减少人力成本并降低贸易纠纷</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">结算中心</div>
        <div>电子对账电子开票，解决票据散乱、糊涂账等财会问题</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">绩效管理</div>
        <div>销售目标及销售政策配置，业绩目标可视化管理</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">任务工单</div>
        <div>任务工单分配及流转管理，提升业务运转效率</div>
      </div>
      <div class="pdc-list-item">
        <div class="title">意见反馈</div>
        <div>建立厂家和经销商的沟通通道，减少信息差</div>
      </div>
    </div>
    <div class="process-info">
      <div class="pdc-title">流程介绍</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="process-img left" src="../../../assets/images/product/process-supplychain-1.png">
        </swiper-slide>
        <swiper-slide>
          <img class="process-img right" src="../../../assets/images/product/process-supplychain-2.png">
        </swiper-slide>
        <div class="process-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="application">
      <div class="pdc-title">应用场景</div>
      <div class="app-list">
        <div class="app-item">
          <img src="../../../assets/images/product/erpno.png" class='topic'>
          <div class="title">品牌厂家无ERP系统</div>
          <div>可采用上游企业端+下游经销商端的整体供应链协同解决方案</div>
        </div>
        <div class="app-item">
          <img src="../../../assets/images/product/erp.png" class='topic'>
          <div class="title">品牌厂家有ERP系统</div>
          <div>可采用API对接上游企业ERP系统+下游经销商端</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="pdc-title">服务优势</div>
      <div class="adv-list-item">
        <div class="title">打通上下游线上贸易链路</div>
        <div>实现厂家和经销商全渠道全链路供需调配和精准对接，通过系统承载降本增效</div>
      </div>
      <div class="adv-list-item">
        <div class="title">全盘经销商精细化管理</div>
        <div>精细化、系统化管理经销商信息、政策，促进厂家政策落地，实现业务增长</div>
      </div>
      <div class="adv-list-item">
        <div class="title">提升贸易安全性</div>
        <div>通过电子签约代替纸质合同签署及货物签收，证据链线上存证，提升贸易安全性</div>
      </div>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, swiper, swiperSlide, SignButton
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.process-scrollbar',
        },
        freeMode : true,
      }
    
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.supply-chain-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
}
</style>